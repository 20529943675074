<script setup lang="ts">
import { type PdpCertification } from '~/types/storyblok/shared-content/pdp-certifications';

interface Props {
  certification: PdpCertification
}

const props = defineProps<Props>()
const normalizedDescription = useRichtext(computed(() => props.certification.description))
</script>
<template>
        <li
          class="certification"
          data-test="product-feature"
        >
          <div
            class="icon"
            aria-hidden="true"
            :style="`background-image:url(${certification?.icon?.filename})`"
            data-test="product-feature-icon"
          />
         <div
          v-if="certification.description"
          class="description"
          v-html="normalizedDescription"
        />
        </li>
  </template>

<style lang="scss" scoped>
  .certification {
    display: grid;
    gap: 1.2rem;
    grid-template-columns: 2.2rem 1fr;
  }
  .icon {
    width: 2.2rem;
    height: 2.2rem;
    display: block;
    border-radius: 50%;
    background-size: contain;
    background-position: center;
  }
  .description {
    font-weight: 400;
    line-height: 2.4rem;
  }
</style>
