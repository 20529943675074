<script setup lang="ts">
import xss from 'xss'
import type { Product, ProductCompositions, ProductLegalMaterials } from 'types/models/product'
import type { PdpCertification } from '~/types/storyblok/shared-content/pdp-certifications'

interface Props {
  product: Product
}

const props = defineProps<Props>()

const { $t } = useNuxtApp()
const product = toRef(props, 'product')

const storyblokStore = useStoryblokStore()
const { state: storyblok } = storeToRefs(storyblokStore)

const sustainabilityContent = useRichtext(computed(() => storyblok?.value?.pdpSustainability?.description))
const shippingContent = useRichtext(computed(() => storyblok?.value?.pdpShippingAndReturns?.description))
const productDescription = computed(() => xss(product.value.description.full))
const materials = computed(() => xss(product.value.information.composition))
const legalMaterials = computed(() => product.value.information.legalMaterials)
const formattedProductId = computed(() => {
  const id = product.value.sku
  return id ? `ID: ${id}` : ''
})

const { pdpCertifications: certifications } = storyblok.value

const normalizedXOCertifications = product.value.certifications.map((c) => {
  return c.toLowerCase()
})

const filteredCertifications = certifications?.filter(certification => normalizedXOCertifications.includes(certification.id.toLowerCase()))

interface AccordionSection {
  title: string
  description?: string
  richText?: boolean
  ariaLabel: string
  productFeatures?: Product
  productId?: string
  compositions?: ProductCompositions[]
  certifications?: PdpCertification[]
  legalMaterials?: ProductLegalMaterials[]
}

const accordionSections: AccordionSection[]
  = [
    { title: 'productDescription', description: productDescription.value, ariaLabel: 'product', productFeatures: product.value, certifications: filteredCertifications, productId: formattedProductId.value },
    { title: 'materials', description: legalMaterials.value.length > 0 ? '' : materials.value, ariaLabel: 'materials', legalMaterials: legalMaterials.value, compositions: filteredCertifications && filteredCertifications?.length > 0 ? product.value.information.compositions : [] },
    { title: 'sustainability', description: sustainabilityContent.value, richText: true, ariaLabel: 'sustainability' },
    { title: 'shippingAndReturns', description: shippingContent.value, richText: true, ariaLabel: 'shipping and returns' },
  ]

const expandedSections = ref(new Set([0]))

function toggleAccordion(index: number) {
  expandedSections.value.has(index) ? expandedSections.value.delete(index) : expandedSections.value.add(index)
}

function compositionText(text: string) {
  const formatText = text.split(' ').join('-').toLowerCase()
  const translated = $t(`composition-${formatText}`)
  return translated
}
</script>

<template>
  <section class="product-information" :aria-label="$t('productInformationSection')">
    <section class="product-description" data-test="product-description">
      <template v-for="(section, index) in accordionSections">
        <AccordionProduct
          v-if="section.title" :key="`${section.title}-${index}`" :title="$t(`${section.title}`)"
          :expanded="expandedSections.has(index)" :data-test="`product-${section.title}-section`" class="top-divider" @toggle-accordion="toggleAccordion(index)"
        >
          <div>
            <div :aria-label="$t(`${section.title}`)" :data-test="`product-${section.title}-text`">
              <p v-if="!section.richText" class="text">
                {{ section.description }}
              </p>
              <div v-else class="text" v-html="section.description" />
            </div>
            <section class="text info">
              <ProductFeatures
                v-if="section.productFeatures" :product="section.productFeatures"
                data-test="product-features"
              />
              <ProductCertifications v-if="section.certifications" :certifications="filteredCertifications" />
            </section>
            <div v-if="section.productId" class="product-code" :data-test="`product-${section.title}-productId`">
              {{ section.productId }}
            </div>
            <div v-if="section.legalMaterials && section.legalMaterials.length > 0" class="text no-margin-top" :data-test="`product-${section.title}-legalMaterials`">
              <div v-for="(legalMaterial, legalMaterialIndex) in section.legalMaterials" :key="legalMaterialIndex">
                <span v-if="section.legalMaterials.length > 1">{{ $t('item').toUpperCase() }} {{ legalMaterialIndex + 1 }}: </span>
                <span>
                  {{ legalMaterial.map(({ name, percent }) => `${percent}% ${compositionText(name)}`).join(', ') }}
                </span>
              </div>
            </div>
            <div v-if="section.compositions && section.compositions.length > 0" class="text margin-bottom" :data-test="`product-${section.title}-compositions`">
              <p>{{ $t('detailedInformation') }}:</p>
              <div v-for="(legalMaterial, legalMaterialIndex) in section.compositions" :key="legalMaterialIndex">
                <span v-if="section.compositions.length > 1">{{ $t('item').toUpperCase() }} {{ legalMaterialIndex + 1 }}: </span>
                <span>
                  {{ legalMaterial.map(({ name, percent }) => `${percent}% ${compositionText(name)}`).join(', ') }}
                </span>
              </div>
            </div>
          </div>
        </AccordionProduct>
      </template>
    </section>
  </section>
</template>

<style lang="scss" scoped>
@import 'assets/scss/rules/breakpoints';
@import 'assets/scss/typography/body';

.product-information {
  width: 100%;
  background-color: var(--gray-lighter);

  .top-divider {
    border-top: 1px solid var(--divider);
  }

  .product-description {
    padding: 1.6rem 2rem 3.4rem;
  }

  .product-code {
    width: 100%;
    font-size: 1.4rem;
    margin-bottom: 2.4rem;
    text-transform: capitalize;
    color: var(--text-secondary);
  }
}

.text {
  @include body1;
  margin: 0.8rem 0rem 2.4rem;
}

.no-margin-top {
  margin-top: -3.4rem; // Remove the margin bottom from the previous element not displayed
}

.margin-bottom {
  margin-bottom: 4.4rem;
}

:deep(.accordion button) {
  @include body1;
}

@media (min-width: $tablet) {
  .product-description {
    padding: 1.5rem 2rem;
  }

  .text {
    @include body2;
  }

  :deep(.accordion button) {
    @include body2;
  }
}

.info {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
</style>
