<script setup lang="ts">
import { type PdpCertification } from '~/types/storyblok/shared-content/pdp-certifications';

interface Props {
  certifications: PdpCertification[]
}

const props = defineProps<Props>()

</script>
<template>
  <ul 
      v-if="certifications.length"
      :aria-label="$t('pdpCertifications')"
      class="certifications"      
      data-test="pdp-certifications">
      <ProductCertificationDescription  v-for="certification in props.certifications" :certification="certification"/>
  </ul>
</template>

<style lang="scss" scoped>
.certifications {
  gap: 2rem;
  display: grid;
}
</style>
